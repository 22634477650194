:root {
  --brand-1: #1877ff;
  --brand-2: #4692ff;
  --brand-3: #bad6ff;
}
.ChatApp,
.MessageContainer,
.Navbar,
.Message .Bubble,
.QuickReplies,
.ChatFooter {
  background-repeat: no-repeat;
  background-size: cover;
}
.MessageContainer {
  background-image: linear-gradient(180deg, #1b3346 0%, #f2f2f2 100%);
}
:root {  }
