.custom-button {
  width: 150px; /* Set a minimum width for the buttons */
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 65%;
  margin-bottom: 32px;
}

@media screen and (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .button-wrapper {
    flex-direction: column;
    max-width: 100%;
  }

  .custom-button + .custom-button {
    margin-top: 16px;
  }
}
